<template>
  <div id="center">
    <div class="up">
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.title">
        <p class="ml-3 colorBlue fw-b">{{item.title}}</p>
        <div>
          <dv-digital-flop :config="item.number" style="width:100px;height:50px;" />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1">预警分布情况</span>
        <dv-scroll-ranking-board :config="ranking" style="height:290px" v-if="isRankingReady"/>
      </div>
      <div class="percent">
        <div class="item  bg-color-black">
          <span>计划完成率</span>
          <centerChart1 :tips="rate.plan_finish_rate"/>
        </div>
        <div class="item  bg-color-black">
          <span>测试完成率</span>
          <centerChart2 :tips="rate.test_finish_rate"/>
        </div>
        <div class="item  bg-color-black">
          <span>课程完成率</span>
          <centerChart3 :tips="rate.course_finish_rate"/>
        </div>
        <div class="item  bg-color-black">
          <span>咨询完成率</span>
          <centerChart4 :tips="rate.consult_finish_rate"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import centerChart1 from "@/components/echart/center/centerChart1";
import centerChart2 from "@/components/echart/center/centerChart2";
import centerChart3 from "@/components/echart/center/centerChart3";
import centerChart4 from "@/components/echart/center/centerChart4";
import ApiService from "../services/api_service";

export default {
  components: {
    centerChart1,
    centerChart2,
    centerChart3,
    centerChart4,
  },
  data() {
    return {
      isRankingReady: false,
      rate: {
         'plan_finish_rate': 0,
         'test_finish_rate': 0,
         'course_finish_rate': 0,
         'consult_finish_rate': 0,
      },
      titleItem: [
        {
          title: "今日计划次数",
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "今日测试次数",
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "今日观看视频次数",
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          }
        },
        {
          title: "今日预约咨询次数",
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          }
        },
      ],
      ranking: {
        data: [],
        waitTime: 10000,
        carousel: "page",
        unit: "个",
        rowNum: 8,
      }
    };
  },
  async mounted() {
    let res = await new ApiService().GetCorpDataScreen('center')
    if (res.status === 200) {
      this.titleItem.forEach((item, index) => {
        if (index === 0) {
          item.number.number[0] = res.data.data['title'].plan_total;
        } else if (index === 1) {
          item.number.number[0] = res.data.data['title'].test_total;
        } else if (index === 2) {
          item.number.number[0] = res.data.data['title'].course_total;
        } else if (index === 3) {
          item.number.number[0] = res.data.data['title'].consult_total;
        }
        item.number = {...item.number};
      });

      this.$set(this.rate, 'plan_finish_rate', res.data.data['rate'].plan_finish_rate);
      this.$set(this.rate, 'test_finish_rate', res.data.data['rate'].test_finish_rate);
      this.$set(this.rate, 'course_finish_rate', res.data.data['rate'].course_finish_rate);
      this.$set(this.rate, 'consult_finish_rate', res.data.data['rate'].consult_finish_rate);

       this.ranking.data = res.data.data['ranks']
      this.isRankingReady = true
    }
  },
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 5px;
      padding-top: 1rem;
      margin-top: 0.5rem;
      width: 24.5%;
      height: 70px;
    }
  }
  .down {
    padding: 4px;
    padding-bottom: 0px;
    width: 100%;
    display: flex;
    height: 330px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 50%;
        height: 162px;
        span {
          margin-top: 15px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
