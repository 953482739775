<template>
  <div id="centreLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">内容统计</span>
          <dv-decoration-3 style="width:100px;height:20px; position:relative;top:-3px;" />
        </div>
      </div>
      <div class="d-flex jc-center" v-if="false">
        <centreLeft1Chart />
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div class="item-box" v-for="(item,index) in numberData" :key="index">
          <div class="d-flex">
            <dv-digital-flop :config="item.number" style="width:100px;height:40px;" />
          </div>
          <p class="text" style="text-align: center;">
            {{item.text}}
            <span class="colorYellow">(个)</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import centreLeft1Chart from "@/components/echart/centreLeft1Chart";
import ApiService from "../services/api_service";
export default {
  data() {
    return {
      chartData: {},
      numberData: [
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          },
          text: "学生人数"
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          },
          text: "班级数量"
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          },
          text: "咨询师"
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          },
          text: "发布计划"
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          },
          text: "完成测评数量"
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          },
          text: "心理数据量"
        },
      ]
    };
  },
  components: {
    centreLeft1Chart
  },
  async mounted() {
    // this.changeTiming();

    let res = await new ApiService().GetCorpDataScreen('centre.left1.numberData')
    if (res.status === 200) {
      this.numberData.forEach((item, index) => {
        /*
        if (index === 0) {
          item.number.number[0] = res.data.data.plan_num;
        } else if (index === 1) {
          item.number.number[0] = res.data.data.test_num;
        } else if (index === 2) {
          item.number.number[0] = res.data.data.course_num;
        } else if (index === 3) {
          item.number.number[0] = res.data.data.counselor_num;
        } else if (index === 4) {
          item.number.number[0] = res.data.data.article_num;
        } else if (index === 5) {
          item.number.number[0] = res.data.data.event_num;
        }
         */
        if (index === 0) {
          item.number.number[0] = res.data.data.user_num;
        } else if (index === 1) {
          item.number.number[0] = res.data.data.level_num;
        } else if (index === 2) {
          item.number.number[0] = res.data.data.counselor_num;
        } else if (index === 3) {
          item.number.number[0] = res.data.data.plan_num;
        } else if (index === 4) {
          item.number.number[0] = res.data.data.test_order_num;
        } else if (index === 5) {
          item.number.number[0] = res.data.data.test_report_num;
        }
        item.number = {...item.number};
      });
    }
  },
  methods: {
  }
};
</script>

<style lang="scss">
#centreLeft1 {
  padding: 1rem;
  height: 410px;
  min-width: 300px;
  border-radius: 5px;
  .bg-color-black {
    height: 385px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 1rem;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }

  .bottom-data {
    .item-box {
      float: right;
      position: relative;
      width: 33%;
      color: #d3d6dd;
      // 金币
      .coin {
        position: absolute;
        left: 0px;
        top: 17px;
        font-size: 2rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
</style>
