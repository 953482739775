<template>
  <div style="position: relative">
    <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-area"></icon>
        </span>
      <div class="d-flex" style="justify-content: space-around;
    justify-items: center;
    align-items: center;
    width: 100%;">
        <span class="fs-xl text mx-2">总体指标雷达图</span>
        <span class="fs-xl text mx-2">总体指标柱状图</span>
        <div class="decoration2">
          <dv-decoration-2 :reverse="true" style="width:5px;height:480px;" />
        </div>
      </div>
    </div>
    <div style="position: absolute;left: 50px; top: 40px;z-index: 999;">
      <select :value="selectValue" style="width: 200px;height: 20px;" @change="onChange">
        <option v-for="(sd,i) in selectData" :key="i" :value="sd.value">{{ sd.label }}</option>
      </select>
    </div>
    <div id="bottomRightChart1" style="width:50%;height:480px;float: right;"></div>
    <div id="bottomRightChart2" style="width:50%;height:480px;float: right;"></div>
  </div>
</template>

<script>
import ApiService from "../../../services/api_service";

const echarts = require("echarts");
export default {
  data() {
    return {
      isReady1: false,
      isReady2: false,
      chartData1: {
        category: [],
        data: [],
      },
      chartData2: {
        category: [],
        data: [],
      },
      selectData: [],
      selectValue: 0
    };
  },
  async mounted() {
    let res1 = await new ApiService().GetCorpDataScreen('bottom.right.selectData')
    if (res1.status === 200) {
      this.selectData = res1.data.data
      await this.onChange(null, this.selectData[0].value)
    }
  },
  methods: {
    async onChange(ev,val){
      if (ev) {
        this.selectValue = ev.target.value
      }else if (val){
        this.selectValue = val
      }

      this.isReady1 = true
      this.isReady2 = true

      let res1 = await new ApiService().GetCorpDataScreen('bottom.right.chartData1', this.selectValue)
      if (res1.status === 200) {
        this.chartData1 = res1.data.data
        this.isReady1 = true
      }
      let res2 = await new ApiService().GetCorpDataScreen('bottom.right.chartData2', this.selectValue)
      if (res2.status === 200) {
        this.chartData2 = res2.data.data
        this.isReady2 = true
      }

      this.drawRadar();
      this.drawBar();
    },
    drawPie1() {
      // 基于准备好的dom，初始化echarts实例
      let myChartPieLeft = echarts.init(
        document.getElementById("bottomRightChart1")
      );
      //  ----------------------------------------------------------------

      // 颜色设置
      let color = {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#f5b44d"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)"
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)"
            }
          ]
        }
      };
      let option = {
        tooltip: {
          trigger: "item"
        },
        grid: {
          left: '10%',
          right: '4%',
          bottom: 40,
          // top: "60%",
          top: '15%',
        },
        xAxis: {
          type: "category",
          position: "bottom",
          axisLine: true,
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12
          },
          data: this.chartData1.category
        },
        // 下方Y轴
        yAxis: {
          name: "订单",
          nameLocation: "end",
          nameGap: 24,
          nameTextStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize: 14
          },
          splitNumber: 4,

          axisLine: {
            lineStyle: {
              opacity: 0
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              opacity: 0.1
            }
          },
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12
          }
        },
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: "#fff"
              }
            },
            lineStyle: {
              normal: {
                color: color.linearBtoG,
                width: 3
              }
            },
            areaStyle: {
              normal: {
                color: color.areaBtoG
              }
            },
            data: this.chartData1.data,
            lineSmooth: true,
            tooltip: {
              position: "top",
              formatter: "{c} m",
              backgroundColor: "rgba(28,152,232,.2)",
              padding: 6
            }
          },

        ]
      };

      myChartPieLeft.setOption(option);
      // -----------------------------------------------------------------
      // 响应式变化
      window.addEventListener("resize", () => myChartPieLeft.resize(), false);
    },
    drawPie2() {
      // 基于准备好的dom，初始化echarts实例
      let myChartPieLeft = echarts.init(
        document.getElementById("bottomRightChart2")
      );
      //  ----------------------------------------------------------------

      // 颜色设置
      let color = {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#f5b44d"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)"
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)"
            }
          ]
        }
      };
      let option = {
        tooltip: {
          trigger: "item"
        },
        grid: {
          left: '10%',
          right: '4%',
          bottom: 40,
          // top: "60%",
          top: '15%',
        },
        xAxis: {
          type: "category",
          position: "bottom",
          axisLine: true,
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12
          },
          data: this.chartData2.category
        },
        // 下方Y轴
        yAxis: {
          name: "订单",
          nameLocation: "end",
          nameGap: 24,
          nameTextStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize: 14
          },
          splitNumber: 4,

          axisLine: {
            lineStyle: {
              opacity: 0
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              opacity: 0.1
            }
          },
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12
          }
        },
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: "#fff"
              }
            },
            lineStyle: {
              normal: {
                color: color.linearBtoG,
                width: 3
              }
            },
            areaStyle: {
              normal: {
                color: color.areaBtoG
              }
            },
            data: this.chartData2.data,
            lineSmooth: true,
            tooltip: {
              position: "top",
              formatter: "{c} m",
              backgroundColor: "rgba(28,152,232,.2)",
              padding: 6
            }
          },

        ]
      };

      myChartPieLeft.setOption(option);
      // -----------------------------------------------------------------
      // 响应式变化
      window.addEventListener("resize", () => myChartPieLeft.resize(), false);
    },
    drawRadar(){
      // 基于准备好的dom，初始化echarts实例
      let bottomRightChart1 = echarts.init(
          document.getElementById("bottomRightChart1")
      );
      //  ----------------------------------------------------------------

      let option = {
        color: ['#1370fb',"#9fe6b8"],
        legend: {
          data: ['参考得分', '团体得分']
        },
        radar: {
          indicator: this.chartData1.category
        },
        series: [
          {
            type: 'radar',
            data: this.chartData1.data
          }
        ]
      };

      bottomRightChart1.setOption(option);
      // -----------------------------------------------------------------
      // 响应式变化
      window.addEventListener("resize", () => bottomRightChart1.resize(), false);
    },
    drawBar(){
      // 基于准备好的dom，初始化echarts实例
      let bottomRightChart2 = echarts.init(
          document.getElementById("bottomRightChart2")
      );
      //  ----------------------------------------------------------------

      let option = {
        color: ['#1370fb',"#9fe6b8"],
        xAxis: {
          type: 'category',
          data: this.chartData2.category
        },
        yAxis: {
          type: 'value'
        },
        series: this.chartData2.data
      };

      bottomRightChart2.setOption(option);
      // -----------------------------------------------------------------
      // 响应式变化
      window.addEventListener("resize", () => bottomRightChart2.resize(), false);
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
