<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 style="width:33.3%;height:5px;" />
          <div class="d-flex jc-center">
            <dv-decoration-8 :color="['#568aea', '#000000']" style="width:200px;height:50px;" />
            <div class="title">
              <span class="title-text">{{ corp.name }}<br>大数据可视化平台</span>
              <dv-decoration-6
                class="title-bototm"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
                style="width:250px;height:8px;"
              />
            </div>
            <dv-decoration-8
              :reverse="true"
              :color="['#568aea', '#000000']"
              style="width:200px;height:50px;"
            />
          </div>
          <dv-decoration-10 style="width:33.3%;height:5px;" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex" style="width: 40%">
            <div
              class="react-right ml-4"
              style="width: 100%; text-align: left;background-color: #0f1325;"
            >
              <span class="react-before"></span>
              <span class="text fw-b"></span>
            </div>
            <div class="react-right ml-3" style="background-color: #0f1325;">
              <span class="text colorBlue fw-b"></span>
            </div>
          </div>
          <div style="width: 40%" class="d-flex">
            <div class="react-left mr-3" style="background-color: #0f1325;">
              <span class="text fw-b"></span>
            </div>
            <div
              class="react-left mr-4"
              style="width:100%; background-color: #0f1325; text-align: right;"
            >
              <span class="react-after"></span>
              <span class="text">{{ nowTime }}</span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <dv-border-box-12>
                <centreLeft1 />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12>
                <centreLeft2 />
              </dv-border-box-12>
            </div>
            <!-- 中间 -->
            <div>
              <center />
            </div>
            <!-- 中间 -->
            <div>
              <dv-border-box-13>
                <centreRight2 />
              </dv-border-box-13>
            </div>
            <div>
              <dv-border-box-13>
                <centreRight1 />
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第四行数据 -->
          <div class="bototm-box">
            <dv-border-box-13>
              <bottomLeft />
            </dv-border-box-13>
            <dv-border-box-12>
              <bottomRight />
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import centreLeft1 from "./centreLeft1";
import centreLeft2 from "./centreLeft2";
import centreRight1 from "./centreRight1";
import centreRight2 from "./centreRight2";
import center from "./center";
import bottomLeft from "./bottomLeft";
import bottomRight from "./bottomRight";
import ApiService from "../services/api_service";
export default {
  data() {
    return {
      corp: {},
      code: '',
      loading: true,
      timer: '', //定义的计数器
      nowTime: ''
    };
  },
  computed:{

  },
  components: {
    centreLeft1,
    centreLeft2,
    centreRight1,
    centreRight2,
    center,
    bottomLeft,
    bottomRight
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    }
  },
  async mounted() {
    this.cancelLoading();

    let res = await new ApiService().GetQuery(this.code)
    if (res.status === 200) {
      this.corp = res.data.data
    }

    this.timer = setInterval(() => {
      let date = new Date();
      let year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      this.nowTime = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
      // return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    }, 1000);
  },
  created() {
    this.code = this.$route.query['corpCode'];
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";
</style>
