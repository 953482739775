<template>
  <div>
    <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
      <div class="d-flex" style="justify-content: space-around;
    justify-items: center;
    align-items: center;
    width: 100%;">
        <span class="fs-xl text mx-2">计划完成统计图</span>
        <span class="fs-xl text mx-2">测试完成统计图</span>
      </div>
    </div>
    <div id="bottomLeftChart1" style="width:50%;height:230px;float: left;"></div>
    <div id="bottomLeftChart2" style="width:50%;height:230px;float: left"></div>
    <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
      <div class="d-flex" style="justify-content: space-around;
    justify-items: center;
    align-items: center;
    width: 100%;">
        <span class="fs-xl text mx-2">课程观看统计图</span>
        <span class="fs-xl text mx-2">预约咨询统计图</span>
      </div>
    </div>

    <div id="bottomLeftChart3" style="width:50%;height:230px;float: left;"></div>
    <div id="bottomLeftChart4" style="width:50%;height:230px;float: left;"></div>
  </div>
</template>

<script>
import ApiService from "../../../services/api_service";

const echarts = require("echarts");
export default {
  data() {
    return {
      isReady1: false,
      isReady2: false,
      isReady3: false,
      isReady4: false,
      chartData1: {
        category: [],
        data: [],
      },
      chartData2: {
        category: [],
        data: [],
      },
      chartData3: {
        category: [],
        data: [],
      },
      chartData4: {
        category: [],
        data: [],
      }
    };
  },
  async mounted() {
    let res1 = await new ApiService().GetCorpDataScreen('bottom.left.chartData1')
    if (res1.status === 200) {
      this.chartData1 = res1.data.data
      this.isReady1 = true
    }
    let res2 = await new ApiService().GetCorpDataScreen('bottom.left.chartData2')
    if (res2.status === 200) {
      this.chartData2 = res2.data.data
      this.isReady2 = true
    }
    let res3 = await new ApiService().GetCorpDataScreen('bottom.left.chartData3')
    if (res3.status === 200) {
      this.chartData3 = res3.data.data
      this.isReady3 = true
    }
    let res4 = await new ApiService().GetCorpDataScreen('bottom.left.chartData4')
    if (res4.status === 200) {
      this.chartData4 = res4.data.data
      this.isReady4 = true
    }

    this.drawPie1();
    this.drawPie2();
    this.drawPie3();
    this.drawPie4();
  },
  methods: {
    drawPie1() {
      let _this = this
      // 基于准备好的dom，初始化echarts实例
      let myChartPieLeft = echarts.init(
        document.getElementById("bottomLeftChart1")
      );
      //  ----------------------------------------------------------------
      let option = {
        title: {
          text: "",
          x: "center",
          y: 0,
          textStyle: {
            color: "#B4B4B4",
            fontSize: 16,
            fontWeight: "normal"
          }
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC"
            }
          }
        },
        legend: {
          show: false,
        },
        grid: {
          x: "4%",
          width: "88%",
          y: "4%"
        },
        xAxis: {
          data: _this.chartData1.category,
          axisLine: {
            lineStyle: {
              color: "#B4B4B4"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },

            axisLabel: {
              formatter: "{value} "
            }
          },
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisLabel: {
              formatter: "{value} "
            }
          }
        ],
        series: [
          {
            name: "计划完成次数",
            type: "line",
            smooth: true,
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#37a2da"
              }
            },
            data: _this.chartData1.data
          },
        ]
      };
      myChartPieLeft.setOption(option);
      // -----------------------------------------------------------------
      // 响应式变化
      window.addEventListener("resize", () => myChartPieLeft.resize(), false);
    },
    drawPie2() {
      let _this = this
      // 基于准备好的dom，初始化echarts实例
      let myChartPieLeft = echarts.init(
          document.getElementById("bottomLeftChart2")
      );
      //  ----------------------------------------------------------------
      let option = {
        title: {
          text: "",
          x: "center",
          y: 0,
          textStyle: {
            color: "#B4B4B4",
            fontSize: 16,
            fontWeight: "normal"
          }
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC"
            }
          }
        },
        legend: {
          show: false,
        },
        grid: {
          x: "4%",
          width: "88%",
          y: "4%"
        },
        xAxis: {
          data: _this.chartData2.category,
          axisLine: {
            lineStyle: {
              color: "#B4B4B4"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },

            axisLabel: {
              formatter: "{value} "
            }
          },
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisLabel: {
              formatter: "{value} "
            }
          }
        ],
        series: [
          {
            name: "计划完成次数",
            type: "line",
            smooth: true,
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#37a2da"
              }
            },
            data: _this.chartData2.data
          },
        ]
      };
      myChartPieLeft.setOption(option);
      // -----------------------------------------------------------------
      // 响应式变化
      window.addEventListener("resize", () => myChartPieLeft.resize(), false);
    },
    drawPie3() {
      // 基于准备好的dom，初始化echarts实例
      let myChartPieLeft = echarts.init(
          document.getElementById("bottomLeftChart3")
      );
      //  ----------------------------------------------------------------

      // 颜色设置
      let color = {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#f5b44d"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)"
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)"
            }
          ]
        }
      };
      let option = {
        tooltip: {
          trigger: "item"
        },
        grid: {
          left: '10%',
          right: '4%',
          bottom: 40,
          // top: "60%",
          top: '15%',
        },
        xAxis: {
          type: "category",
          position: "bottom",
          axisLine: true,
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12
          },
          data: this.chartData3.category
        },
        // 下方Y轴
        yAxis: {
          name: "订单",
          nameLocation: "end",
          nameGap: 24,
          nameTextStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize: 14
          },
          splitNumber: 4,

          axisLine: {
            lineStyle: {
              opacity: 0
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              opacity: 0.1
            }
          },
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12
          }
        },
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: "#fff"
              }
            },
            lineStyle: {
              normal: {
                color: color.linearBtoG,
                width: 3
              }
            },
            areaStyle: {
              normal: {
                color: color.areaBtoG
              }
            },
            data: this.chartData3.data,
            lineSmooth: true,
            tooltip: {
              position: "top",
              formatter: "{c} m",
              backgroundColor: "rgba(28,152,232,.2)",
              padding: 6
            }
          },

        ]
      };

      myChartPieLeft.setOption(option);
      // -----------------------------------------------------------------
      // 响应式变化
      window.addEventListener("resize", () => myChartPieLeft.resize(), false);
    },
    drawPie4() {
      // 基于准备好的dom，初始化echarts实例
      let myChartPieLeft = echarts.init(
          document.getElementById("bottomLeftChart4")
      );
      //  ----------------------------------------------------------------

      // 颜色设置
      let color = {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#f5b44d"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)"
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)"
            }
          ]
        }
      };
      let option = {
        tooltip: {
          trigger: "item"
        },
        grid: {
          left: '10%',
          right: '4%',
          bottom: 40,
          // top: "60%",
          top: '15%',
        },
        xAxis: {
          type: "category",
          position: "bottom",
          axisLine: true,
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12
          },
          data: this.chartData4.category
        },
        // 下方Y轴
        yAxis: {
          name: "订单",
          nameLocation: "end",
          nameGap: 24,
          nameTextStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize: 14
          },
          splitNumber: 4,

          axisLine: {
            lineStyle: {
              opacity: 0
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              opacity: 0.1
            }
          },
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12
          }
        },
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: "#fff"
              }
            },
            lineStyle: {
              normal: {
                color: color.linearBtoG,
                width: 3
              }
            },
            areaStyle: {
              normal: {
                color: color.areaBtoG
              }
            },
            data: this.chartData4.data,
            lineSmooth: true,
            tooltip: {
              position: "top",
              formatter: "{c} m",
              backgroundColor: "rgba(28,152,232,.2)",
              padding: 6
            }
          },

        ]
      };

      myChartPieLeft.setOption(option);
      // -----------------------------------------------------------------
      // 响应式变化
      window.addEventListener("resize", () => myChartPieLeft.resize(), false);
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
