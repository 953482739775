<template>
  <div style="display: flex; justify-content: center; justify-items: center;align-items: center;align-content: center;">
    <div id="centerChart4" style="width:120px;height:130px;"></div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  props: {
    tips: {
      type: Number,
    }
  },
  data() {
    return {};
  },
  watch:{
    tips(newVal,oldVal){
      console.log(newVal,oldVal)
      this.drawPie();
    }
  },
  mounted() {
    this.drawPie();
  },
  methods: {
    drawPie() {
      // 基于准备好的dom，初始化echarts实例
      let myChartPieLeft = echarts.init(
        document.getElementById("centerChart4")
      );
      //  ----------------------------------------------------------------
      let option = {
        title: [
          {
            text: this.tips * 1 + "%",
            x: "center",
            y: "center",
            textStyle: {
              color: "#ffdb5c",
              fontSize: 16
            }
          }
        ],
        series: [
          {
            type: "pie",
            radius: ["75%", "80%"],
            center: ["50%", "50%"],
            hoverAnimation: false,
            color: ["#f3ffb070", "transparent"],
            label: {
              normal: {
                show: false
              }
            },
            data: [
              {
                value: this.tips,
                itemStyle: {
                  normal: {
                    color: "#ffdb5c",
                    shadowBlur: 10,
                    shadowColor: "#ffdb5c"
                  }
                }
              },
              {
                value: 100 - this.tips
              }
            ]
          }
        ]
      };
      myChartPieLeft.setOption(option);
      // -----------------------------------------------------------------
      // 响应式变化
      window.addEventListener("resize", () => myChartPieLeft.resize(), false);
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
