<template>
  <div id="centreLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-pie"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">预警等级人数占比</span>
          <dv-decoration-1 style="width:100px;height:20px; position:relative;top:-3px;" />
        </div>
      </div>
      <div class="d-flex jc-center">
        <centreLeft2Chart />
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div class="item-box" v-for="(item,index) in numberData" :key="index">
          <div class="d-flex">
            <dv-digital-flop :config="item.number" style="width:100px;height:40px;" />
          </div>
          <p class="text" style="text-align: center;">
            {{item.text}}
            <span class="colorYellow">(个)</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import centreLeft2Chart from "@/components/echart/centreLeft2Chart";
import ApiService from "../services/api_service";

export default {
  data() {
    return {
      numberData: [
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          },
          text: "预警人数"
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}"
          },
          text: "总人数"
        },
      ]
    };
  },
  components: {
    centreLeft2Chart
  },
  async mounted() {

    let res = await new ApiService().GetCorpDataScreen('centre.left2.numberData')
    if (res.status === 200) {
      this.numberData.forEach((item, index) => {
        if (index === 0) {
          item.number.number[0] = res.data.data.warning_num;
        } else if (index === 1) {
          item.number.number[0] = res.data.data.total_num;
        }
        item.number = {...item.number};
      });
    }
  },
  methods: {}
};
</script>

<style lang="scss">
#centreLeft1 {
  padding: 1rem;
  height: 410px;
  min-width: 300px;
  border-radius: 5px;
  .bg-color-black {
    height: 385px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 1rem;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }


  .bottom-data {
    .item-box {
      float: left;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      // 金币
      .coin {
        position: absolute;
        left: 0px;
        top: 17px;
        font-size: 2rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
</style>
