import axios from 'axios'


let domain = document.domain || ""
let apiDomain = 'https://api.xixindao.cn/api';

// @MODIFY
if (domain === "localhost" || domain === "127.0.0.1") {
  apiDomain = "http://localhost:10050/api"
}

axios.defaults.headers.common = {
  Accept: 'application/json'
}

axios.interceptors.request.use(function (config) {
  config.headers['X-Corp-Token'] = localStorage.getItem('corpToken') || '';
  config.headers['X-User-Token'] = localStorage.getItem('userToken') || '';
  return config;
})

axios.interceptors.response.use(function (res ){
  return res;
}, function (error) {
  ApiService.errorHandle(error)
})

class ApiService {
  constructor() {
    this.apiDomain = apiDomain
    this.version = "/v1/"
    this.axios = axios
  }

  request = function (url, params, method) {
    let _this = this;
    method = method || 'GET';
    let urlFormat = _this.apiDomain + _this.version + url;

    if (method.toUpperCase() === 'POST') {
      return _this.axios.post(urlFormat, params);
    } else {
      var esc = encodeURIComponent;
      var query = Object.keys(params)
          .map(k => esc(k) + '=' + esc(params[k]))
          .join('&');
      return _this.axios.get(urlFormat + '?' + query);
    }
  }

  GetQuery(code) {
    return this.request("corp/query", {
      code: code
    }, "get")
  }

  GetCorpDataScreen(flag, testId) {
    return this.request("corp/dataScreen", {
      flag: flag,
      test_id: testId
    }, "get")
  }

  static errorHandle(error) {
    console.error(error)
    let msg = '服务器开小差了～'
    if (error.response && error.response.data.msg) {
      msg = error.response.data.msg
    }
    console.error(msg)
  }
}

export default ApiService
