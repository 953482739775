<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">测评使用排行榜</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board :config="config" style="width:290px;height:360px" v-if="isTableReady"/>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../services/api_service";

export default {
  data() {
    return {
      isTableReady: false,
      config: {
        header: ["名称", "次数"],
        data: [],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [20,200,60],
        align: ["center"]
      }
    };
  },
  components: {},
  async mounted() {
    let res = await new ApiService().GetCorpDataScreen('centre.right1.tableData')
    if (res.status === 200) {
      this.config.data = res.data.data.tables
      this.isTableReady = true
    }

  },
  methods: {}
};
</script>

<style lang="scss">
#centreRight1 {
  padding: 1rem;
  height: 410px;
  min-width: 300px;
  border-radius: 5px;
  .bg-color-black {
    height: 385px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
  }

}
</style>
