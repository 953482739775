<template>
  <div>
    <div id="centreLeft1Chart" style="width:260px; height: 220px;"></div>
  </div>
</template>

<script>
import ApiService from "../../services/api_service";

const echarts = require("echarts");
export default {
  data() {
    return {
      chartData: {},
    };
  },
  async mounted() {
    let res = await new ApiService().GetCorpDataScreen('centre.left1.chartData')
    if (res.status === 200) {
      this.chartData = res.data.data
    }

    this.drawPie();
  },
  methods: {
    drawPie(sidebar) {
      // 基于准备好的dom，初始化echarts实例
      let myChartPieLeft = echarts.init(
          document.getElementById("centreLeft1Chart")
      );
      //  ----------------------------------------------------------------

      myChartPieLeft.setOption({
        color: [
          "#37a2da",
          "#32c5e9",
          "#9fe6b8",
          "#ffdb5c",
          "#ff9f7f",
          "#fb7293",
          "#e7bcf3",
          "#8378ea"
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        toolbox: {
          show: true
        },
        calculable: true,
        legend: {
          orient: "horizontal",
          icon: "circle",
          bottom: 0,
          x: "center",
          data: ["学生人数", "班级数量", "咨询师", "发布计划", "完成测评数量", "心理数据量"],
          textStyle: {
            color: "#fff"
          }
        },
        series: [
          {
            name: "内容统计",
            type: "pie",
            radius: [10, 60],
            roseType: "area",
            center: ["50%", "40%"],
            data: [
              {value: this.chartData.user_num, name: "学生人数"},
              {value: this.chartData.level_num, name: "班级数量"},
              {value: this.chartData.counselor_num, name: "咨询师"},
              {value: this.chartData.plan_num, name: "发布计划"},
              {value: this.chartData.test_order_num, name: "完成测评数量"},
              {value: this.chartData.test_report_num, name: "心理数据量"},
            ]
          }
        ]
      });
      // -----------------------------------------------------------------
      // 响应式变化
      window.addEventListener("resize", () => myChartPieLeft.resize(), false);
      //侧边栏变化
      if (sidebar) {
        myChartPieLeft.resize();
      }
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
